body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input.removeArrows::-webkit-outer-spin-button,
input.removeArrows::-webkit-inner-spin-button,
.removeArrows input::-webkit-outer-spin-button,
.removeArrows input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.removeArrows[type="number"],
.removeArrows input[type="number"] {
  -moz-appearance: textfield;
}
 
#do-login{ 
  background: #429793;
  background-image: -webkit-linear-gradient(left, #3077ae, #429793);
  border: none;
  color: white;
}

.menuBox{
   background: -webkit-linear-gradient(left, #3077ae, #429793) !important;
}

.gWmmTS .menuLogoContainer,.ehObMk{
  /* border-bottom: 1px solid white !important;  */
  border-bottom: none !important;
}

.httUSP.selected, .httUSP:hover, .dEWVwR.selected, .dEWVwR:hover{
  background: linear-gradient(90deg,  #3077ae  0%,#87b881 100%) !important; 
}

.dEWVwR.selected, .dEWVwR:hover{
  background: #3077ae !important; 
}

.cIIzoM .statusArrow{
  background: #429793 !important;
}
.httUSP .iconContainer,.dEWVwR .iconContainer{
  background: none !important;
  border:1px solid #429793 !important;
}

.httUSP.selected  .iconContainer{
border:1px solid #fff !important;
}

.gWmmTS::-webkit-scrollbar-thumb{
   background: #c1c1c1 !important;
}

.gWmmTS .menuItems{
  padding-bottom: 100px;
}